import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@services';
import { APIRoutes } from '@constants';
import {
  CreateNewPasswordRequest,
  LoginRequest,
  LoginResponse,
  PasswordUpdateTokenResponse,
  UserRolDetail,
} from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private url = APIRoutes.ACCOUNT;

  constructor(private apiService: ApiService) {}

  public login(request: LoginRequest): Observable<LoginResponse> {
    return this.apiService.post(`${this.url}/login`, request);
  }

  public roles(): Observable<UserRolDetail> {
    return this.apiService.get(`${this.url}/user-roles`);
  }

  public sendPasswordResetEmail(email: string): Observable<void> {
    return this.apiService.post(
      `${this.url}/send-password-reset-notification`,
      { email },
    );
  }

  public changePassword(request: CreateNewPasswordRequest): Observable<void> {
    return this.apiService.post(`${this.url}/change-password`, request);
  }

  public passwordUpdateToken(): Observable<PasswordUpdateTokenResponse> {
    return this.apiService.get(`${this.url}/password-update-token`);
  }
}
