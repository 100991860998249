import { Injectable } from '@angular/core';
import { ApiService } from '@services';
import { Observable } from 'rxjs';
import { APIRoutes } from '@constants';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  private url = APIRoutes.PAYMENTS;

  constructor(private apiService: ApiService) {}

  public patchExpirationDate(
    paymentId: string,
    newExpirationDate: string,
  ): Observable<void> {
    return this.apiService.patch(`${this.url}/${paymentId}/expiration-date`, {
      newExpirationDate,
    });
  }

  public patchAprrovalOrder(paymentId: string): Observable<void> {
    return this.apiService.patch(`${this.url}/seller/${paymentId}/pay`, {});
  }
}
