import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AccordionTableComponent } from './accordion-table/accordion-table.component';
import {
  BasicDialogComponent,
  OptionDialogComponent,
  ChangePasswordDialogComponent,
} from './dialogs';
import { MenuTooltipComponent } from './menu-tooltip/menu-tooltip.component';
import { DatetimeFieldComponent } from './datetime-field/datetime-field.component';
import { AngularMaterialModule } from '@angular-material';
import { provideNativeDateAdapter } from '@angular/material/core';
import { InfoTooltipComponent } from './info-tooltip/info-tooltip.component';
import { ChipSelectComponent } from './chip-select/chip-select.component';
import { PasswordFormComponent } from './templates/password-form/password-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    AccordionTableComponent,
    BasicDialogComponent,
    MenuTooltipComponent,
    DatetimeFieldComponent,
    OptionDialogComponent,
    InfoTooltipComponent,
    ChipSelectComponent,
    PasswordFormComponent,
    ChangePasswordDialogComponent,
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
  ],
  exports: [
    AccordionTableComponent,
    BasicDialogComponent,
    MenuTooltipComponent,
    DatetimeFieldComponent,
    OptionDialogComponent,
    InfoTooltipComponent,
    ChipSelectComponent,
    PasswordFormComponent,
    ChangePasswordDialogComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [provideNativeDateAdapter(), DatePipe],
})
export class ComponentsModule {}
