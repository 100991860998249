/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private apiUrl: string = environment.apiUrl;
  private httpOptions = {
    headers: {
      ['Content-Type']: 'application/json',
      ['Accept']: 'application/json, text/plain, */*',
    },
    responseType: 'json',
  };

  constructor(private http: HttpClient) {}

  public get<T>(path: string, options?: any): Observable<any> {
    return this.http.get<T>(`${this.apiUrl}${path}`, {
      ...this.httpOptions,
      ...options,
    });
  }

  public put<T, B>(path: string, body: B, options?: any): Observable<any> {
    return this.http.put<T>(`${this.apiUrl}${path}`, JSON.stringify(body), {
      ...this.httpOptions,
      ...options,
    });
  }

  public patch<T, B>(path: string, body: B, options?: any): Observable<any> {
    const httpOptions = {
      ...this.httpOptions,
      ...options,
      headers: {
        ...this.httpOptions.headers,
        ...(options && options.headers),
      },
    };

    if (body instanceof FormData && httpOptions.headers['Content-Type']) {
      delete httpOptions.headers['Content-Type'];
    }

    return this.http.patch<T>(`${this.apiUrl}${path}`, body, httpOptions);
  }

  public post<T, B>(path: string, body: B, options?: any): Observable<any> {
    return this.http.post<T>(`${this.apiUrl}${path}`, body, {
      ...this.httpOptions,
      ...options,
    });
  }

  public delete<T>(path: string, options?: any): Observable<any> {
    return this.http.delete<T>(`${this.apiUrl}${path}`, {
      ...this.httpOptions,
      ...options,
    });
  }
}
