import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatDialogModule,
    MatSortModule,
    MatMenuModule,
    MatDatepickerModule,
  ],
  exports: [
    MatSidenavModule,
    MatSnackBarModule,
    MatDialogModule,
    MatSortModule,
    MatMenuModule,
    MatDatepickerModule,
  ],
})
export class AngularMaterialModule {}
