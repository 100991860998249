<div class="flex flex-row gap-2 bg-transparent justify-center items-center">
  <ui-paragraph
    textColor="neutrals-900"
    fontWeight="regular"
    type="p14"
    mobile-type="p12"
    [content]="date | date: 'dd/MM/yyyy'"
  />

  <ui-icon
    *ngIf="!isLoading"
    class="dynamic-con flex-none"
    name="calendar"
    size="16"
    mobile-size="14"
    fill="primary-500"
    [matMenuTriggerFor]="calendarMenu"
    #menuTrigger="matMenuTrigger"
    (menuOpened)="onOpenCalendar()"
  />

  <ui-loader
    *ngIf="isLoading"
    class="progresol"
    type="primary"
    size="sm"
  ></ui-loader>
</div>

<mat-menu #calendarMenu="matMenu" class="w-[300px] mt-[14px]">
  <mat-calendar
    #calendar
    (click)="$event.stopPropagation()"
    class="bg-neutrals-0 rounded-[8px]"
    [(selected)]="selectedDate"
    (selectedChange)="onSelectedDate($event)"
  ></mat-calendar>
</mat-menu>
