<div class="flex flex-col gap-2">
  <div
    class="flex flex-row justify-between bg-white px-6 py-2 shadow-lg cursor-pointer rounded-[8px]"
    #accordionFace
    (click)="toggleAccordion()"
    (keydown.enter)="toggleAccordion()"
    tabindex="accordion-index"
  >
    <ng-content select="[titleAccordion]"></ng-content>

    <!-- Animated Chevron -->
    <div class="flex cursor-pointer flex-col items-center justify-center">
      <button #buttonSpinner class="bg-transparent">
        <ui-icon
          name="chevron-down"
          fill="primary-400"
          size="24"
          mobile-size="20"
        />
      </button>
    </div>
  </div>

  <div #tableOrdersContainer>
    <ng-content select="[contentAccordion]"></ng-content>
  </div>
</div>
