import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PalleteColor } from 'nanaykuna-ui-kit/loader';

@Component({
  selector: 'digicem-chip-select',
  templateUrl: './chip-select.component.html',
})
export class ChipSelectComponent implements OnChanges {
  @Input() content = '';
  @Input() selected = false;
  protected colorText: PalleteColor = this.getColorText(this.selected);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selected']) {
      this.colorText = this.getColorText(this.selected);
    }
  }

  private getColorText(selected: boolean): PalleteColor {
    return selected ? 'success-300' : 'neutrals-700';
  }
}
