import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
import { FormStatus } from 'src/app/core/data/enums/form.enum';

@Component({
  selector: 'digicem-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
})
export class ChangePasswordDialogComponent {
  public token: string;

  constructor(
    private dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    private cookieService: CookieService,
    @Inject(MAT_DIALOG_DATA) public data: { token: string },
  ) {
    this.token = data.token;
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  protected handleOnChangeStatus(status: FormStatus): void {
    switch (status) {
      case FormStatus.SENT:
        this.cookieService.delete('needsPasswordChange');
        break;
      case FormStatus.FINISHED:
        this.dialogRef.close();
        break;
      case FormStatus.CLOSED:
        this.closeDialog();
        break;
    }
  }
}
