<div
  class="flex items-center justify-start gap-2 w-full h-[32px] rounded-lg px-4"
  [ngStyle]="{ 'background-color': selected ? '#E4FAE2' : '#EDF4FB' }"
>
  <ui-icon
    *ngIf="selected"
    name="check-filled"
    size="16"
    mobile-size="16"
    fill="success-300"
  ></ui-icon>
  <ui-paragraph
    type="p12"
    mobile-type="p12"
    [attr.text-color]="colorText"
    font-weight="semibold"
    [content]="content"
  ></ui-paragraph>
</div>
