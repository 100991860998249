export enum AppRoutes {
  DEFAULT_EMPTY = '',
  LOGIN = 'login',
  FORGOT_PASSWORD = 'forgot-password',
  SELLER_PORTAL = 'seller-portal',
  ORDERS = 'order-list',
  ORDER_DETAIL = 'order-detail',
  HISTORICAL = 'historical-orders',
  HISTORIC_ORDER_DETAIL = 'order-historic-detail',
  PENDING_PAYMENTS = 'pending-payments',
  PENDING_PAYMENT_DETAIL = 'pending-payment-detail',
  CREATE_NEW_PASSWORD = 'create-new-password',
}
