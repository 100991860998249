<button
  class="flex w-6 h-6 items-center justify-center"
  [matMenuTriggerFor]="menuTooltipBox"
  #menuTrigger="matMenuTrigger"
  (menuOpened)="onOpenTooltip()"
>
  <ui-icon
    [attr.name]="icon"
    [attr.fill]="fill"
    [attr.size]="size"
    [attr.mobile-size]="mobileSize"
  />
</button>

<mat-menu #menuTooltipBox="matMenu" class="bg-neutrals-0">
  <div
    class="box-border p-5 w-[272px] flex flex-col gap-4"
    (click)="$event.stopPropagation()"
  >
    <!-- Content Proyection -->
    <ng-content select="[menuTooltipContent]"></ng-content>

    <div class="flex justify-end">
      <ui-button
        type="primary"
        responsive="false"
        size="sm"
        button-text="Entendido"
        (clicked)="menuTrigger.closeMenu()"
      />
    </div>
  </div>
</mat-menu>
