import { Component, Input } from '@angular/core';

@Component({
  selector: 'digicem-info-tooltip',
  templateUrl: './info-tooltip.component.html',
})
export class InfoTooltipComponent {
  @Input() title: string = '';
  @Input() content: string = '';
  protected isVisible: boolean = false;

  protected showTooltip(): void {
    this.isVisible = true;
  }

  protected hideTooltip(): void {
    this.isVisible = false;
  }
}
