/* eslint-disable @typescript-eslint/no-explicit-any */
import { AbstractControl, FormGroup } from '@angular/forms';

export function getFormControl(
  controlName: string,
  form: FormGroup,
): AbstractControl<any, any> {
  return form.controls[controlName];
}

export function setFormErrors(controlName: string, form: FormGroup): boolean {
  if (
    !form.get(controlName)?.touched &&
    !form.get(controlName)?.dirty &&
    form.get(controlName)?.pristine
  ) {
    return false;
  }

  return (form.get(controlName)?.hasError('email') ||
    form.get(controlName)?.hasError('required') ||
    form.get(controlName)?.hasError('min') ||
    form.get(controlName)?.hasError('max') ||
    form.get(controlName)?.hasError('passwordMismatch')) as boolean;
}

export function getControlErrorDetail(
  controlName: string,
  form: FormGroup,
): string | undefined {
  const control: any = form.get(controlName);
  if (control) {
    if (control?.hasError('required')) {
      return 'Campo obligatorio';
    }
    if (control?.hasError('email')) {
      return 'No es un correo electrónico válido';
    }
    if (control?.hasError('passwordMismatch')) {
      return 'Las contraseñas no coinciden';
    }
  }
  return undefined;
}

export function setNumericStringToInteger(
  valueToParse: string | number,
): number {
  return parseInt(valueToParse + '', 10);
}
