import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { LoginService } from '@services';
import { getFormControl, setFormErrors, getControlErrorDetail } from '@utils';
import { catchError, EMPTY } from 'rxjs';
import { FormStatus } from 'src/app/core/data/enums/form.enum';

@Component({
  selector: 'digicem-password-form',
  templateUrl: './password-form.component.html',
})
export class PasswordFormComponent implements AfterViewInit {
  @Input() public token: string = '';
  @Input() public title: string = 'Crea tu nueva contraseña';
  @Input() public subTitle: string =
    'Ingresa tu nueva contraseña en los campos a continuación.';
  @Input() public buttonText: string = 'Crear contraseña';
  @Input() public titleSuccess: string = '¡Contraseña creada con éxito!';
  @Input() public messageSuccess: string =
    'Tu nueva contraseña ha sido creada correctamente. Ahora ya puedes iniciar sesión en tu cuenta.';
  @Input() public buttonSuccessText: string = 'Iniciar sesión';
  @Input() public isDialog: boolean = false;
  @Output() public onChangeStatusForm: EventEmitter<FormStatus> =
    new EventEmitter();

  protected newPasswordForm!: FormGroup;
  protected loading = false;
  protected error = '';
  protected formSubmitted = false;

  protected hasUpperCase = false;
  protected hasLowerCase = false;
  protected hasNumber = false;
  protected hasSpecialChar = false;
  protected hasMinLength = false;

  @ViewChild('confirmPassword') confirmPasswordRef!: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private renderer: Renderer2,
  ) {
    this.setNewPasswordForm();
  }

  ngAfterViewInit(): void {
    this.renderer.listen(
      this.confirmPasswordRef.nativeElement,
      'paste',
      event => {
        event.preventDefault();
      },
    );
  }

  private setNewPasswordForm(): void {
    this.newPasswordForm = this.formBuilder.group({
      newPassword: new FormControl<string>(
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(/[A-Z]/), // Al menos una mayúscula
          Validators.pattern(/[a-z]/), // Al menos una minúscula
          Validators.pattern(/[0-9]/), // Al menos un número
          Validators.pattern(/[\W_]/), // Al menos un carácter especial
        ]),
      ),
      confirmPassword: new FormControl<string>('', [
        Validators.required,
        this.matchPassword.bind(this),
      ]),
    });

    this.newPasswordForm.get('newPassword')?.valueChanges.subscribe(() => {
      this.newPasswordForm.get('confirmPassword')?.updateValueAndValidity();
    });
  }

  private matchPassword(
    control: AbstractControl,
  ): { [key: string]: boolean } | null {
    if (!this.newPasswordForm) return null;
    return control.value === this.newPasswordForm.get('newPassword')?.value
      ? null
      : { passwordMismatch: true };
  }

  protected onInputed(event: CustomEvent, formControl: string): void {
    this.newPasswordForm.controls[formControl].setValue(event.detail);
    if (formControl === 'newPassword') {
      this.onChangePassword(event.detail);
    }
  }

  public onChangePassword(password: string): void {
    this.hasUpperCase = /[A-Z]/.test(password);
    this.hasLowerCase = /[a-z]/.test(password);
    this.hasNumber = /[0-9]/.test(password);
    this.hasSpecialChar = /[\W_]/.test(password);
    this.hasMinLength = password.length >= 8;
  }

  private applyErrorCleanerTimeout(): void {
    setTimeout(() => {
      this.error = '';
    }, 8000);
  }

  protected onSubmit(): void {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.loginService
      .changePassword({
        ...this.newPasswordForm.value,
        token: this.token,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.loading = false;
          this.error = error.error.detail;
          this.applyErrorCleanerTimeout();
          return EMPTY;
        }),
      )
      .subscribe(() => {
        this.onChangeStatusForm.emit(FormStatus.SENT);
        this.formSubmitted = true;
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected control(controlName: string): AbstractControl<any, any> {
    return getFormControl(controlName, this.newPasswordForm);
  }

  protected setErrors(controlName: string): boolean {
    return setFormErrors(controlName, this.newPasswordForm);
  }

  protected getError(controlName: string): string | undefined {
    return getControlErrorDetail(controlName, this.newPasswordForm);
  }

  protected finishForm(): void {
    this.onChangeStatusForm.emit(FormStatus.FINISHED);
  }

  protected closeDialog(): void {
    this.onChangeStatusForm.emit(FormStatus.CLOSED);
  }
}
