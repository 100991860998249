import { Injectable } from '@angular/core';
import { ApiService } from '@services';
import { Observable } from 'rxjs';
import { OrdersPaginated, PaginationPayload } from '@interfaces';
import {
  DeliveredOrder,
  OrderById,
  PatchShippingDetail,
  PendingPaymentClient,
} from '@models';
import { APIRoutes } from '@constants';

@Injectable({
  providedIn: 'root',
})
export class SaleOrdersService {
  private url = APIRoutes.SALE_ORDERS;

  constructor(private apiService: ApiService) {}

  public getUndeliveredOrders(
    params: PaginationPayload,
  ): Observable<OrdersPaginated> {
    return this.apiService.get(
      `v2/${this.url}/sellers/undelivered?_end=${
        params.page * params.perPage
      }&_order=DESC&_sort=id&_start=${
        params.page * params.perPage - params.perPage
      }`,
    );
  }

  public patchUndeliveredOrder(request: {
    saleOrderIds: string[];
  }): Observable<void> {
    return this.apiService.patch(`${this.url}/sellers/undelivered`, request);
  }

  public getOrderById(id: string): Observable<OrderById> {
    return this.apiService.get(
      `${this.url}/undelivered/search/sale-order-id?id=${id}`,
    );
  }

  public getDeliveredOrders(
    year: number,
  ): Observable<Record<string, DeliveredOrder[]>> {
    return this.apiService.get(`v2/${this.url}/sellers/delivered?year=${year}`);
  }

  public getPendingPayments(): Observable<PendingPaymentClient[]> {
    return this.apiService.get(`v2/${this.url}/sellers/pending-payments`);
  }

  public approveOrder(providerSaleOrderId: string): Observable<void> {
    return this.apiService.patch(
      `${this.url}/${providerSaleOrderId}/approved`,
      {},
    );
  }

  public rejectOrder(saleOrderId: string): Observable<void> {
    return this.apiService.patch(`${this.url}/${saleOrderId}/cancelled`, {});
  }

  public patchShippingDetails(
    shippingDetail: PatchShippingDetail,
  ): Observable<void> {
    const formData = new FormData();
    if (shippingDetail.file) {
      formData.append('file', shippingDetail.file);
    }
    formData.append('observation', shippingDetail.observation);
    return this.apiService.patch(
      `v2/${this.url}/${shippingDetail.saleOrderId}/shipping-details`,
      formData,
    );
  }
}
