<div class="flex items-center justify-center lg:justify-start h-full">
  <form
    [formGroup]="newPasswordForm"
    [ngClass]="{
      'w-[320px]': true,
      'md:w-[512px]': isDialog,
      'lg:w-[512px]': !isDialog,
    }"
    class="relative rounded-[24px] flex flex-col px-8 pt-2 pb-8 bg-neutrals-0 ui-shadow-above"
  >
    <div
      *ngIf="isDialog"
      class="absolute top-4 right-4 cursor-pointer text-neutrals-900 hover:text-primary-600"
      (click)="closeDialog()"
    >
      <ui-icon
        name="close"
        size="20"
        mobile-size="20"
        fill="neutrals-900"
      ></ui-icon>
    </div>

    <div
      class="d-block w-[96px] lg:w-[128px] h-[4px] rounded-lg bg-neutrals-300 mx-auto mb-8"
    ></div>

    <div class="flex flex-col" *ngIf="!formSubmitted">
      <div class="flex flex-col justify-center items-center mb-6">
        <ui-illustrative-icon
          size="m"
          m-size="m"
          name="i-password"
        ></ui-illustrative-icon>
      </div>

      <div class="flex justify-center mb-4">
        <ui-headline
          class="text-center"
          type="h24"
          mobile-type="h18"
          text-color="neutrals-900"
          font-weight="bold"
          [content]="title"
        ></ui-headline>
      </div>
      <div class="flex justify-center lg:mb-6 mb-4">
        <ui-paragraph
          class="text-center"
          type="p16"
          mobile-type="p14"
          text-color="neutrals-900"
          font-weight="regular"
          [content]="subTitle"
        ></ui-paragraph>
      </div>

      <div class="flex flex-col mb-2">
        <ui-input
          ngDefaultControl
          digicemFormControl
          placeholder="Ingresa tu nueva contraseña"
          label="Nueva Contraseña"
          type="password"
          appearance="outline"
          hint-message=""
          (inputed)="onInputed($any($event), 'newPassword')"
          [error]="setErrors('newPassword')"
          [errorMessage]="getError('newPassword')"
          [formControl]="$any(control('newPassword'))"
        ></ui-input>
      </div>

      <div class="flex flex-col mb-4">
        <ui-input
          #confirmPassword
          ngDefaultControl
          digicemFormControl
          placeholder="Repite la nueva contraseña"
          label="Repetir Contraseña"
          type="password"
          appearance="outline"
          hint-message=""
          (inputed)="onInputed($any($event), 'confirmPassword')"
          [error]="setErrors('confirmPassword')"
          [errorMessage]="getError('confirmPassword')"
          [formControl]="$any(control('confirmPassword'))"
        ></ui-input>
      </div>

      <div class="mb-4 lg:mb-6" *ngIf="error">
        <ui-alert type="critical" [message]="error" />
      </div>

      <div class="mb-4">
        <ui-paragraph
          type="p12"
          mobile-type="p12"
          text-color="neutrals-900"
          font-weight="regular"
          content="🔒 Requisitos para tu nueva contraseña:"
        ></ui-paragraph>
      </div>
      <div class="flex flex-col gap-y-1 mb-4">
        <digicem-chip-select
          content="Una letra mayúscula"
          [selected]="hasUpperCase"
        ></digicem-chip-select>
        <digicem-chip-select
          content="Una letra minúscula"
          [selected]="hasLowerCase"
        ></digicem-chip-select>
        <digicem-chip-select
          content="Un número"
          [selected]="hasNumber"
        ></digicem-chip-select>
        <digicem-chip-select
          content="Un carácter especial"
          [selected]="hasSpecialChar"
        ></digicem-chip-select>
        <digicem-chip-select
          content="Al menos 8 caracteres"
          [selected]="hasMinLength"
        ></digicem-chip-select>
      </div>

      <ui-button
        type="primary"
        responsive="false"
        size="lg"
        [attr.button-text]="buttonText"
        [enabled]="newPasswordForm.valid"
        [isLoading]="loading"
        (clicked)="onSubmit()"
      ></ui-button>
    </div>

    <div class="flex flex-col" *ngIf="formSubmitted">
      <div class="flex flex-col justify-center items-center mb-8">
        <ui-animation class="h-[120px] w-[120px]" type="success"></ui-animation>
      </div>

      <div class="flex justify-center mb-6">
        <ui-headline
          class="text-center"
          type="h24"
          mobile-type="h18"
          text-color="neutrals-900"
          font-weight="bold"
          [content]="titleSuccess"
        ></ui-headline>
      </div>
      <div class="flex justify-center lg:mb-10 mb-8">
        <ui-paragraph
          class="text-center"
          type="p16"
          mobile-type="p14"
          text-color="neutrals-900"
          font-weight="regular"
          [content]="messageSuccess"
        ></ui-paragraph>
      </div>

      <ui-button
        type="primary"
        responsive="false"
        size="lg"
        [attr.button-text]="buttonSuccessText"
        (clicked)="finishForm()"
      ></ui-button>
    </div>
  </form>
</div>
