import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'digicem-accordion-table',
  templateUrl: './accordion-table.component.html',
})
export class AccordionTableComponent implements AfterViewInit {
  @ViewChild('accordionFace', { static: false })
  protected toggleLine!: ElementRef<HTMLDivElement>;
  @ViewChild('buttonSpinner', { static: false })
  protected btnChevron!: ElementRef<HTMLButtonElement>;
  @ViewChild('tableOrdersContainer', { static: false })
  protected orderTable!: ElementRef<HTMLDivElement>;
  protected toggle = false;

  ngAfterViewInit(): void {
    this.btnChevron.nativeElement.style.transition = '0.4s';
    this.orderTable.nativeElement.style.overflow = 'hidden';
    this.orderTable.nativeElement.style.transition = 'height 0.4s ease-in-out';
    this.toggleAccordioTable();
  }

  protected toggleAccordion(): void {
    this.toggle = !this.toggle;
    this.toggleAccordioTable();
  }

  private toggleAccordioTable(): void {
    const btnStyle = this.btnChevron.nativeElement as HTMLButtonElement;
    const tableStyle = this.orderTable.nativeElement as HTMLDivElement;
    if (this.toggle) {
      btnStyle.style.transform = 'rotate(180deg)';
    } else {
      btnStyle.style.transform = 'rotate(0deg)';
    }

    if (this.toggle) {
      tableStyle.style.height = `${tableStyle.scrollHeight}px`;
    } else {
      tableStyle.style.height = '0px';
    }
  }
}
