import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { PaymentsService } from '@services';
import { MatCalendar } from '@angular/material/datepicker';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'digicem-datetime-field',
  templateUrl: './datetime-field.component.html',
})
export class DatetimeFieldComponent implements AfterViewInit {
  @Input() date?: string;
  @Input() paymentId: string = '';
  @Output() readonly result: EventEmitter<{
    paymentId: string;
    formattedDate: string;
  }> = new EventEmitter();

  protected isLoading = false;
  protected selectedDate: Date | null = null;

  @ViewChild('calendar', { static: false })
  calendar!: MatCalendar<Date>;

  @ViewChild('menuTrigger') menuTrigger!: MatMenuTrigger;

  constructor(
    private paymentsService: PaymentsService,
    private snackBar: MatSnackBar,
    private datePipe: DatePipe,
  ) {}

  ngAfterViewInit(): void {
    this.selectedDate = this.date ? new Date(this.date) : null;
  }

  private closeMenu() {
    if (this.menuTrigger) {
      this.menuTrigger.closeMenu();
    }
  }

  protected onOpenCalendar(): void {
    this.calendar._goToDateInView(this.selectedDate ?? new Date(), 'month');
  }

  protected onSelectedDate(date: Date | null): void {
    if (date instanceof Date) {
      this.closeMenu();
      const formattedDate = this.datePipe.transform(date, 'yyyy-MM-dd');
      if (formattedDate) {
        this.isLoading = true;
        this.paymentsService
          .patchExpirationDate(this.paymentId, formattedDate)
          .subscribe({
            next: () => {
              this.isLoading = false;
              this.selectedDate = date;
              this.result.emit({
                paymentId: this.paymentId,
                formattedDate: formattedDate,
              });
            },
            error: error => {
              const errorMessage =
                error.status === 422
                  ? 'Fecha inválida, solo se acepta fechas mayores a hoy.'
                  : 'No se pudo actualizar la fecha del pedido.';
              this.isLoading = false;
              this.selectedDate = this.date ? new Date(this.date) : null;
              this.snackBar.open(errorMessage, 'Cerrar', {
                duration: 5000,
              });
            },
          });
      }
    }
  }
}
