/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';

@Component({
  selector: 'digicem-option-dialog',
  templateUrl: './option-dialog.component.html',
})
export class OptionDialogComponent {
  protected detail: string;
  protected detail2: string;
  protected title: string;
  protected onAccept: (id: string) => void;
  protected isLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      detail: string;
      detail2: string;
      title: string;
      objectId: string;
      onAccept: (id: string) => void;
      action: Observable<any>;
      errorMessage?: string;
    },
    private dialog: MatDialogRef<OptionDialogComponent>,
    private snackBar: MatSnackBar,
  ) {
    this.detail = data.detail;
    this.detail2 = data.detail2;
    this.title = data.title;
    this.onAccept = data.onAccept;
  }

  protected onAcceptClick(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.data.action.subscribe({
      next: () => {
        this.onAccept(this.data.objectId);
        this.dialog.close();
      },
      error: () => {
        this.snackBar.open(
          this.data.errorMessage ?? 'No se pudo pagar el pedido.',
          'Cerrar',
          {
            duration: 5000,
          },
        );
        this.dialog.close();
      },
    });
  }

  protected onCancel(): void {
    this.dialog.close();
  }
}
