<ui-animation
  *ngIf="!token"
  class="h-[300px] w-[300px]"
  type="loader"
></ui-animation>

<digicem-password-form
  *ngIf="token"
  [isDialog]="true"
  [token]="token"
  title="Cambia tu contraseña"
  subTitle="Para empezar, actualiza tu contraseña y asegura tu cuenta."
  buttonText="Cambiar mi contraseña"
  titleSuccess="¡Contraseña cambiada con éxito!"
  messageSuccess="Tu contraseña ha sido actualizada correctamente. La próxima vez que ingreses a tu cuenta, utiliza tu nueva contraseña."
  buttonSuccessText="Aceptar"
  (onChangeStatusForm)="handleOnChangeStatus($event)"
>
</digicem-password-form>
