import { Component, Input } from '@angular/core';
import { PalleteColor, IconName } from 'nanaykuna-ui-kit/loader';

@Component({
  selector: 'digicem-menu-tooltip',
  templateUrl: './menu-tooltip.component.html',
})
export class MenuTooltipComponent {
  @Input() icon: IconName = 'info-outline';
  @Input() fill: PalleteColor = 'neutrals-700';
  @Input() size: string = '24';
  @Input() mobileSize: string = '20';
  @Input() onOpenTooltipCallback?: () => void;

  protected onOpenTooltip(): void {
    if (this.onOpenTooltipCallback) {
      this.onOpenTooltipCallback();
    }
  }
}
