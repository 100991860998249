import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'digicem-basic-dialog',
  templateUrl: './basic-dialog.component.html',
})
export class BasicDialogComponent {
  protected detail: string;
  protected title: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      detail: string;
      title: string;
    },
    private dialog: MatDialogRef<BasicDialogComponent>,
  ) {
    this.detail = data.detail;
    this.title = data.title;
  }

  protected onBackOffice(): void {
    window.open(environment.backOfficeUrl, '_blank');
    this.dialog.close();
  }

  protected onTryAgain(): void {
    this.dialog.close();
  }
}
