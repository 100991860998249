<div class="flex flex-col py-4 px-8 md:py-6 md:px-12 bg-white">
  <div class="flex justify-center mb-6">
    <div class="ml-8">
      <ui-illustrative-icon
        name="i-warning"
        size="l"
        mobile-size="m"
      ></ui-illustrative-icon>
    </div>
  </div>
  <div class="flex justify-center mb-2">
    <ui-headline
      class="text-center"
      type="h20"
      mobile-type="h16"
      text-color="primary-700"
      font-weight="semibold"
      [content]="title"
    ></ui-headline>
  </div>
  <div class="flex justify-center mb-6">
    <ui-paragraph
      class="text-center"
      type="p16"
      mobile-type="p14"
      text-color="neutrals-700"
      [content]="detail"
    ></ui-paragraph>
  </div>
  <div *ngIf="detail2" class="flex justify-center mb-6">
    <ui-paragraph
      class="text-center"
      type="p16"
      mobile-type="p14"
      text-color="neutrals-700"
      [content]="detail2"
    ></ui-paragraph>
  </div>
  <div class="hidden md:flex flex-col lg:flex-row justify-center">
    <div class="mr-0 lg:mr-2 mb-2 lg:mb-0 w-full lg:w-auto">
      <ui-button
        type="primary"
        responsive="false"
        size="lg"
        button-text="Si, estoy seguro"
        [enabled]="true"
        [isLoading]="isLoading"
        (clicked)="onAcceptClick()"
      ></ui-button>
    </div>
    <div class="w-full lg:w-auto">
      <ui-button
        type="secondary"
        responsive="false"
        size="lg"
        button-text="Ahora no"
        [enabled]="true"
        [isLoading]="false"
        (clicked)="onCancel()"
      ></ui-button>
    </div>
  </div>
  <div class="flex md:hidden flex-col lg:flex-row justify-center">
    <div class="mr-0 lg:mr-2 mb-2 lg:mb-0 w-full lg:w-auto">
      <ui-button
        type="primary"
        responsive="false"
        size="sm"
        button-text="Si, estoy seguro"
        [enabled]="true"
        [isLoading]="isLoading"
        (clicked)="onAcceptClick()"
      ></ui-button>
    </div>
    <div class="w-full lg:w-auto">
      <ui-button
        type="secondary"
        responsive="false"
        size="sm"
        button-text="Ahora no"
        [enabled]="true"
        [isLoading]="false"
        (clicked)="onCancel()"
      ></ui-button>
    </div>
  </div>
</div>
