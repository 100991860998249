<div class="relative inline-block">
  <!-- Content Projection -->
  <div
    class="inline-block"
    (mouseenter)="showTooltip()"
    (mouseleave)="hideTooltip()"
  >
    <ng-content select="[contentAction]"></ng-content>
  </div>

  <!-- Tooltip -->
  <div
    *ngIf="isVisible"
    class="absolute top-full -right-7 transform translate-x-0 mt-2 bg-white text-sm rounded-lg py-5 px-5 shadow-lg z-10 transition-opacity duration-200 w-[272px]"
  >
    <div class="flex flex-col">
      <ui-paragraph
        text-color="neutrals-900"
        font-weight="bold"
        mobile-type="p12"
        type="p14"
        [content]="title"
        class="mb-2"
      />
      <ui-paragraph
        text-color="neutrals-900"
        font-weight="regular"
        mobile-type="p10"
        type="p12"
        [content]="content"
      />
    </div>
    <!-- Tooltip Arrow -->
    <div
      class="absolute top-0 right-8 transform -translate-y-full w-0 h-0 border-l-[8px] border-l-transparent border-r-[8px] border-r-transparent border-b-[8px] border-b-white"
    ></div>
  </div>
</div>
